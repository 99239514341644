import React from 'react';

import LogoOutlineWhite from '../../../../assets/brand/LogoOutlineWhite';
import IconMenuHamburger from '../../../../icons/MenuHamburger';
import { useLayoutMenuContext } from '../../context/LayoutMenuContext';
import * as S from './styles';

export function Header() {
  const { onCollapse, isMobile } = useLayoutMenuContext();
  if (!isMobile) return null;
  return (
    <S.Header>
      <IconMenuHamburger onClick={onCollapse} />
      <LogoOutlineWhite />
    </S.Header>
  );
}
