import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

type Props = {
  customStyle?: any;
};

export function IconLoading({ customStyle }: Props) {
  return (
    <Spin
      indicator={<LoadingOutlined style={customStyle} spin />}
      style={{ placeSelf: 'center' }}
    />
  );
}

IconLoading.defaultProps = {
  customStyle: { fontSize: 50 },
};
