import moment from 'moment';

export function getCurrentDateBrazilianFormat() {
  const currentDate: Date = new Date();
  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export function parseToDateFormatted(initialDate: Date, finalDate: Date) {
  let parsedDate: any = '';
  if (moment(initialDate).format('DD/MM/YYYY') === moment(finalDate).format('DD/MM/YYYY')) {
    parsedDate =
      moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
        ? 'Hoje'
        : `${moment(initialDate).locale('pt-br').format('DD/MM/YY')}`;
  } else if (moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
    parsedDate = `Hoje ~ ${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  } else if (moment(finalDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
    parsedDate = `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ Hoje`;
  } else {
    parsedDate =
      `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ ` +
      `${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
  }
  return parsedDate;
}

export function getFollowingFriday(currentDate: Date): Date {
  const friday: number = 5;

  if (currentDate.getDay() > 5) {
    currentDate.setDate(currentDate.getDate() + 6);
  } else {
    const dayDiff: number = friday - currentDate.getDay();
    currentDate.setDate(currentDate.getDate() + dayDiff);
  }

  return currentDate;
}

export function getLastCommercialDateBrazilianFormat(): string {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1) {
    currentDate.setDate(currentDate.getDate() - 3);
  } else if (weekDay === 7) currentDate.setDate(currentDate.getDate() - 2);
  else currentDate.setDate(currentDate.getDate() - 1);

  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
}

export const getDateBrFormat = (currentDate: moment.Moment): string => {
  // if(!currentDate)
  // return getLastCommercialDateBrazilianFormat();
  const dateObj: Date = currentDate.toDate();
  const dt: string =
    dateObj.getDate() > 9 ? dateObj.getDate().toString() : `0${dateObj.getDate().toString()}`;
  const month: string =
    dateObj.getMonth() > 9
      ? (dateObj.getMonth() + 1).toString()
      : `0${(dateObj.getMonth() + 1).toString()}`;
  const year: string = dateObj.getFullYear().toString().substring(2, 4);
  return `${dt}/${month}/${year}`;
};

export function getLastCommercialDateUSFormat(): string {
  const currentDate: Date = new Date();
  const weekDay: number = currentDate.getDay();

  if (weekDay === 1) {
    currentDate.setDate(currentDate.getDate() - 3);
  } else if (weekDay === 7) currentDate.setDate(currentDate.getDate() - 2);
  else currentDate.setDate(currentDate.getDate() - 1);

  const dt: string =
    currentDate.getDate() > 9
      ? currentDate.getDate().toString()
      : `0${currentDate.getDate().toString()}`;
  const month: string = (currentDate.getMonth() + 1).toString();
  const year: string = currentDate.getFullYear().toString();
  return `${year}-${month}-${dt}`;
}

export function createDateObjtWithTmezoffset(currentDate: Date): Date {
  const offset: number = currentDate.getTimezoneOffset();
  currentDate.setHours(currentDate.getHours() + offset / 60);
  return currentDate;
}

export function getLasCommercialMommentDate(): moment.Moment {
  const commercialDate: string = getLastCommercialDateUSFormat();
  const dt: number = Number.parseInt(commercialDate.substring(8, 10));
  const mnth: number = Number.parseInt(commercialDate.substring(5, 7)) - 1;
  const date: moment.Moment = moment().set('date', dt).set('month', mnth);
  return date;
}
