import React from 'react';

export default function LogoNegative() {
  return (
    <svg
      width="200"
      height="49"
      viewBox="0 0 200 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14405 1H34.2825C36.5736 1 38.4266 2.85195 38.4266 5.13116V43.8535C38.4266 46.1327 36.5736 47.9846 34.2825 47.9846H5.14405C2.85299 47.9846 1 46.1327 1 43.8535V5.13116C1 2.85195 2.85299 1 5.14405 1Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M34.2828 11.3527C34.2828 12.5573 33.9247 13.7349 33.2537 14.7365C32.5828 15.7381 31.6292 16.5188 30.5135 16.9798C29.3977 17.4407 28.17 17.5614 26.9856 17.3263C25.8011 17.0913 24.7131 16.5113 23.8592 15.6595C23.0053 14.8077 22.4237 13.7224 22.1881 12.5409C21.9525 11.3595 22.0734 10.1348 22.5356 9.02189C22.9977 7.90896 23.7804 6.95773 24.7845 6.28847C25.7886 5.61922 26.9691 5.26201 28.1768 5.26201C28.9791 5.26032 29.7739 5.4167 30.5155 5.72219C31.257 6.02768 31.9308 6.47625 32.4982 7.04216C33.0655 7.60807 33.5152 8.28017 33.8214 9.01989C34.1277 9.75961 34.2845 10.5524 34.2828 11.3527Z"
        fill="white"
      />
      <path
        d="M31.047 22.7593H24.6453C23.2248 22.7593 22.0732 23.9079 22.0732 25.3248V29.8043C22.0732 31.2213 23.2248 32.3699 24.6453 32.3699H31.047C32.4675 32.3699 33.6191 31.2213 33.6191 29.8043V25.3248C33.6191 23.9079 32.4675 22.7593 31.047 22.7593Z"
        fill="white"
      />
      <path
        d="M14.2028 5.26199H7.80103C6.38054 5.26199 5.229 6.41064 5.229 7.82757V41.2879C5.229 42.7048 6.38054 43.8534 7.80103 43.8534H14.2028C15.6233 43.8534 16.7748 42.7048 16.7748 41.2879V7.82757C16.7748 6.41064 15.6233 5.26199 14.2028 5.26199Z"
        fill="white"
      />
      <path
        d="M68.8916 32.7209C68.1602 33.3609 67.1544 34.0009 65.8744 34.0009C63.863 34.0009 62.7201 33.178 62.7201 30.7094V23.3494H70.3088C70.4916 22.3894 70.6287 21.4752 70.6287 20.4694C70.6287 19.5552 70.4916 18.6409 70.3088 17.7723H62.7201V12.058C61.6687 11.8752 60.5258 11.7837 59.4287 11.7837C58.2858 11.7837 57.1429 11.8752 55.9544 12.058V17.7723H52.2515C52.1144 18.6866 52.0229 19.6009 52.0229 20.5152C52.0229 21.5209 52.1144 22.4809 52.2515 23.3494H55.9544V32.4009C55.9544 37.7037 59.7029 39.8066 64.6858 39.8066C67.9772 39.8066 70.1715 38.618 71.7258 37.3837C71.4972 35.5552 70.2173 33.498 68.8916 32.7209Z"
        fill="white"
      />
      <path
        d="M83.8967 16.7209C79.1424 16.7209 75.7595 18.6866 74.251 20.058V39.1209C75.3938 39.3037 76.491 39.3952 77.6338 39.3952C78.7767 39.3952 79.9653 39.3037 81.0624 39.1209V23.578C81.7938 23.2123 82.9824 22.938 84.3995 22.938C85.3595 22.938 86.1824 23.0294 87.0053 23.258C88.2395 21.7037 88.8795 19.6009 88.9253 17.3609C87.6453 16.9494 85.9538 16.7209 83.8967 16.7209Z"
        fill="white"
      />
      <path
        d="M99.9074 16.8123C95.1531 16.8123 91.7245 18.8694 90.4903 19.9666C90.8103 21.978 91.9531 23.4866 93.416 24.3094C94.5588 23.4409 96.7531 22.4352 99.3131 22.4352C102.513 22.4352 103.793 23.7609 103.793 25.6352V26.5037C102.468 26.0923 100.959 25.9094 99.2217 25.8637C93.6903 25.818 89.7588 28.1494 89.7588 32.7209C89.7588 37.4752 93.3702 39.8523 99.5874 39.8523C103.565 39.8523 107.313 39.0294 110.559 37.7037V26.3209C110.559 19.5552 106.216 16.8123 99.9074 16.8123ZM96.5703 32.4466C96.5703 30.8466 97.896 30.1152 100.045 30.1152C101.462 30.1152 102.696 30.298 103.793 30.618V34.5952C102.696 34.9152 101.599 35.098 100.502 35.098C97.896 35.098 96.5703 34.0466 96.5703 32.4466Z"
        fill="white"
      />
      <path
        d="M125.713 39.898C129.233 39.898 131.884 38.4809 133.438 37.0637C132.89 35.0066 131.655 33.4066 129.964 32.5837C129.004 33.4523 127.861 34.1837 125.758 34.1837C122.238 34.1837 120.638 31.4866 120.638 28.2409C120.638 24.9037 122.375 22.3894 125.713 22.3894C127.358 22.3894 128.638 23.0752 129.553 23.898C131.244 22.8466 132.661 20.9266 133.073 19.098C131.793 17.9552 128.867 16.6752 125.53 16.6752C118.81 16.6752 113.781 21.2009 113.781 28.3323C113.781 35.6466 118.353 39.898 125.713 39.898Z"
        fill="white"
      />
      <path
        d="M150.153 28.3323C153.124 25.6352 155.913 22.1152 158.382 17.7723C157.056 17.5437 155.182 17.4523 153.947 17.4523C152.987 17.4523 151.753 17.4523 150.61 17.5894C148.873 20.9266 146.267 23.7609 143.342 25.6809V9.17801C142.153 8.99516 141.01 8.90373 139.867 8.90373C138.77 8.90373 137.627 8.99516 136.53 9.17801V39.1209C137.764 39.3037 138.862 39.3952 140.004 39.3952C141.147 39.3952 142.29 39.3037 143.342 39.1209V32.8123C143.799 32.6294 144.256 32.4009 144.759 32.1266L151.067 39.3037C151.89 39.3952 153.81 39.4409 154.542 39.4409C156.599 39.4409 158.747 39.1666 159.524 38.8923L150.153 28.3323Z"
        fill="white"
      />
      <path
        d="M172.457 8.62944C167.245 8.62944 163.451 11.6923 163.268 17.8637H160.434C160.251 18.7323 160.16 19.6923 160.16 20.698C160.16 21.7037 160.251 22.618 160.434 23.3952H163.268V39.1209C164.457 39.3494 165.6 39.3952 166.743 39.3952C167.885 39.3952 168.983 39.3494 170.08 39.1209V23.4409H174.834C175.017 22.5723 175.154 21.658 175.154 20.6523C175.154 19.6923 175.017 18.7323 174.834 17.8637H170.08V17.178C170.08 15.0294 171.131 14.2066 172.914 14.2066C174.103 14.2066 174.971 14.5266 175.703 14.9837C176.983 14.1152 178.217 12.058 178.354 10.2294C177.211 9.4523 175.2 8.62944 172.457 8.62944Z"
        fill="white"
      />
      <path
        d="M194.962 17.3152C194.094 17.3152 193.134 17.3609 192.265 17.4523C191.625 22.4809 190.574 27.8294 188.699 32.218C188.562 32.538 188.425 32.9952 188.334 33.4523C188.242 33.1323 188.105 32.7209 187.877 32.218C186.002 27.8752 184.494 22.4809 183.671 17.4523C182.894 17.3609 181.934 17.3152 181.111 17.3152C179.191 17.3152 177.088 17.5894 176.128 17.818C177.819 25.6352 179.831 31.578 184.265 38.4352C182.894 40.3094 180.745 42.2752 178.322 44.1952C178.917 46.1609 180.288 48.1723 182.391 48.9037C192.174 41.9094 197.477 31.7152 199.351 17.8637C198.117 17.5437 196.562 17.3152 194.962 17.3152Z"
        fill="white"
      />
    </svg>
  );
}
