import { Layout } from 'antd';
import styled from 'styled-components';

export const Header = styled(Layout.Header)`
  &&& {
    background: #2f4cdd !important;
    display: flex;
    align-items: center;
    position: relative;
    padding: 2.8rem 2.2rem 1.8rem;
    justify-content: space-between;
    background-color: transparent;
    height: auto;

    &.collapsed {
      flex-direction: column-reverse;

      & > svg:last-child {
        width: 100%;
        max-width: 2.6rem;
        height: 100%;
        margin-bottom: 1rem;
      }

      & > div {
        margin: 0;
      }
    }

    & > svg:last-child {
      width: 96px;
      height: 43px;
      margin-right: auto;
      display: flex;
      align-items: center;
    }
  }
`;

export const IconCollapse = styled.div`
  &&& {
    margin-right: auto;
    cursor: pointer;
  }
`;
