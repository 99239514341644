import React from 'react';

import LogoNegative from '../../../../../assets/brand/LogoNegative';
import IconOutlineWhite from '../../../../../icons/brand/IconOutlineWhite';
import IconMenu from '../../../../../icons/Menu';
import IconMenuHamburger from '../../../../../icons/MenuHamburger';
import { useLayoutMenuContext } from '../../../context/LayoutMenuContext';
import { Header, IconCollapse } from './styles';

export function SideBarHeader() {
  const { onCollapse, collapsed } = useLayoutMenuContext();
  return (
    <Header className={collapsed ? 'collapsed' : ''}>
      <IconCollapse>
        {collapsed ? <IconMenuHamburger onClick={onCollapse} /> : <IconMenu onClick={onCollapse} />}
      </IconCollapse>
      {collapsed ? <IconOutlineWhite /> : <LogoNegative />}
    </Header>
  );
}
