import React from 'react';

export default function Error() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1248 1.87516C9.30899 1.05935 8.26958 0.50378 7.13803 0.278699C6.00647 0.0536189 4.83358 0.169138 3.76768 0.610649C2.70178 1.05216 1.79074 1.79983 1.14976 2.75912C0.508787 3.71841 0.166669 4.84622 0.166669 5.99995C0.166668 7.15367 0.508787 8.28149 1.14976 9.24077C1.79074 10.2001 2.70178 10.9477 3.76768 11.3892C4.83358 11.8308 6.00647 11.9463 7.13803 11.7212C8.26958 11.4961 9.30899 10.9405 10.1248 10.1247C10.6665 9.58306 11.0961 8.94 11.3893 8.23227C11.6825 7.52453 11.8333 6.76599 11.8333 5.99995C11.8333 5.2339 11.6825 4.47536 11.3893 3.76763C11.0961 3.05989 10.6665 2.41683 10.1248 1.87516ZM2.70017 9.29978C2.04752 8.64713 1.60307 7.81561 1.423 6.91037C1.24294 6.00512 1.33536 5.06681 1.68856 4.21409C2.04177 3.36137 2.63991 2.63254 3.40734 2.11976C4.17477 1.60698 5.07702 1.33328 6 1.33328C6.92298 1.33328 7.82523 1.60698 8.59266 2.11976C9.36009 2.63254 9.95823 3.36137 10.3114 4.21409C10.6646 5.06681 10.7571 6.00512 10.577 6.91037C10.3969 7.81561 9.95248 8.64713 9.29983 9.29978C8.42466 10.1749 7.23768 10.6666 6 10.6666C4.76233 10.6666 3.57534 10.1749 2.70017 9.29978ZM8.0624 7.23738L6.82496 5.99995L8.0624 4.76251C8.17179 4.65311 8.23325 4.50474 8.23325 4.35003C8.23325 4.19532 8.17179 4.04695 8.0624 3.93755C7.953 3.82816 7.80463 3.7667 7.64992 3.7667C7.49521 3.7667 7.34684 3.82816 7.23744 3.93755L6 5.17499L4.76256 3.93755C4.65317 3.82816 4.5048 3.7667 4.35009 3.7667C4.19538 3.7667 4.047 3.82816 3.93761 3.93755C3.82821 4.04695 3.76675 4.19532 3.76675 4.35003C3.76675 4.50474 3.82821 4.65311 3.93761 4.76251L5.17504 5.99995L3.93761 7.23738C3.82821 7.34678 3.76675 7.49515 3.76675 7.64986C3.76675 7.80457 3.82821 7.95294 3.93761 8.06234C4.047 8.17174 4.19538 8.2332 4.35009 8.2332C4.5048 8.2332 4.65317 8.17174 4.76257 8.06234L6 6.8249L7.23744 8.06234C7.34684 8.17174 7.49521 8.2332 7.64992 8.2332C7.80463 8.2332 7.953 8.17174 8.0624 8.06234C8.17179 7.95294 8.23325 7.80457 8.23325 7.64986C8.23325 7.49515 8.17179 7.34678 8.0624 7.23738Z"
        fill="#AA2A2A"
      />
    </svg>
  );
}
