import React, { createContext, ReactNode, useState, useContext, useEffect, useMemo } from 'react';

import { useMatchMedia } from '../../../hooks/useMatchMedia';

type ContextValue = {
  isMobile: boolean;
  isTableT: boolean;
  collapsed: boolean;

  setCollapsed: (collapsed: boolean) => void;
  onCollapse: () => void;
};

const LayoutMenuContext = createContext<ContextValue>({} as ContextValue);

type Props = {
  children: ReactNode;
};

export function LayoutMenuContextProvider({ children }: Props) {
  const isMobile: boolean = useMatchMedia(767);
  const isTableT: boolean = useMatchMedia(1200);

  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile) setCollapsed(true);
  }, [isMobile]);

  function onCollapse() {
    setCollapsed(!collapsed);
  }

  const value = useMemo(
    () => ({
      isMobile,
      isTableT,
      collapsed,
      setCollapsed,
      onCollapse,
    }),
    [isMobile, isTableT, collapsed]
  );

  return <LayoutMenuContext.Provider value={value}>{children}</LayoutMenuContext.Provider>;
}

export function useLayoutMenuContext() {
  const context = useContext(LayoutMenuContext);

  if (typeof context === 'undefined') {
    throw new Error('LayoutMenuContext must be used within an LayoutMenuContext');
  }

  return context;
}
