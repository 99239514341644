import React from 'react';

export default function IconDashboard() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.75C14.5859 21.75 17.0658 20.7228 18.8943 18.8943C20.7228 17.0658 21.75 14.5859 21.75 12C21.75 9.41414 20.7228 6.93419 18.8943 5.10571C17.0658 3.27723 14.5859 2.25 12 2.25C11.8011 2.25 11.6103 2.32902 11.4697 2.46967C11.329 2.61032 11.25 2.80109 11.25 3V5.25C11.25 5.44891 11.329 5.63968 11.4697 5.78033C11.6103 5.92098 11.8011 6 12 6C12.1989 6 12.3897 5.92098 12.5303 5.78033C12.671 5.63968 12.75 5.44891 12.75 5.25V3.78367C14.3112 3.9263 15.7995 4.51081 17.0405 5.46877C18.2816 6.42672 19.2239 7.71844 19.7573 9.19266C20.2907 10.6669 20.393 12.2626 20.0522 13.7928C19.7114 15.3231 18.9417 16.7245 17.8331 17.8331C16.7245 18.9417 15.3231 19.7114 13.7928 20.0522C12.2626 20.393 10.6669 20.2907 9.19266 19.7573C7.71844 19.2239 6.42672 18.2816 5.46877 17.0405C4.51081 15.7995 3.9263 14.3112 3.78367 12.75H5.25C5.44891 12.75 5.63968 12.671 5.78033 12.5303C5.92098 12.3897 6 12.1989 6 12C6 11.8011 5.92098 11.6103 5.78033 11.4697C5.63968 11.329 5.44891 11.25 5.25 11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12C2.25292 14.585 3.28108 17.0632 5.10893 18.8911C6.93678 20.7189 9.41503 21.7471 12 21.75Z"
        stroke="none"
        strokeWidth="0.5"
      />
      <path
        d="M10.5558 11.6165C10.5205 11.7413 10.5017 11.8703 10.4999 12C10.4999 12.2967 10.5879 12.5867 10.7527 12.8334C10.9176 13.08 11.1518 13.2723 11.4259 13.3858C11.7 13.4994 12.0016 13.5291 12.2926 13.4712C12.5836 13.4133 12.8508 13.2705 13.0606 13.0607C13.2704 12.8509 13.4133 12.5836 13.4711 12.2926C13.529 12.0017 13.4993 11.7001 13.3858 11.426C13.2722 11.1519 13.08 10.9176 12.8333 10.7528C12.5866 10.588 12.2966 10.5 12 10.5C11.8702 10.5018 11.7412 10.5206 11.6164 10.556L6.15519 5.09475C6.08601 5.02312 6.00325 4.96598 5.91174 4.92667C5.82024 4.88737 5.72183 4.86668 5.62224 4.86581C5.52266 4.86495 5.4239 4.88392 5.33172 4.92163C5.23955 4.95934 5.15581 5.01503 5.08539 5.08545C5.01497 5.15587 4.95928 5.23961 4.92157 5.33179C4.88386 5.42396 4.86489 5.52272 4.86575 5.6223C4.86662 5.72189 4.88731 5.8203 4.92661 5.9118C4.96592 6.00331 5.02306 6.08607 5.09469 6.15525L10.5558 11.6165Z"
        strokeWidth="0.5"
      />
      <path
        d="M15.75 12C15.75 12.1989 15.829 12.3897 15.9697 12.5303C16.1103 12.671 16.3011 12.75 16.5 12.75H18C18.1989 12.75 18.3897 12.671 18.5303 12.5303C18.671 12.3897 18.75 12.1989 18.75 12C18.75 11.8011 18.671 11.6103 18.5303 11.4697C18.3897 11.329 18.1989 11.25 18 11.25H16.5C16.3011 11.25 16.1103 11.329 15.9697 11.4697C15.829 11.6103 15.75 11.8011 15.75 12Z"
        strokeWidth="0.5"
      />
      <path
        d="M15.7124 7.22678L14.6513 8.2875C14.5816 8.35714 14.5263 8.43981 14.4886 8.53081C14.4509 8.62181 14.4314 8.71935 14.4314 8.81786C14.4314 8.91637 14.4507 9.01393 14.4884 9.10495C14.5261 9.19598 14.5813 9.2787 14.6509 9.34838C14.7206 9.41806 14.8032 9.47335 14.8942 9.51108C14.9852 9.54881 15.0828 9.56825 15.1813 9.56828C15.2798 9.56832 15.3773 9.54895 15.4684 9.51128C15.5594 9.47361 15.6421 9.41839 15.7118 9.34875L16.7731 8.28825C16.8428 8.2186 16.8981 8.13591 16.9359 8.04489C16.9736 7.95387 16.993 7.85631 16.9931 7.75778C16.9931 7.65925 16.9737 7.56167 16.9361 7.47063C16.8984 7.37958 16.8432 7.29685 16.7735 7.22715C16.7039 7.15745 16.6212 7.10216 16.5301 7.06442C16.4391 7.02668 16.3416 7.00724 16.243 7.0072C16.1445 7.00717 16.0469 7.02654 15.9559 7.06421C15.8648 7.10189 15.7821 7.15713 15.7124 7.22678Z"
        strokeWidth="0.5"
      />
    </svg>
  );
}
