import { Layout } from 'antd';
import React from 'react';

import { useLayoutMenuContext } from '../../context/LayoutMenuContext';
import { SideBarHeader } from './Header';
import { SideBarUserProfile } from './UserProfile';
import { SideBarMenu } from './Menu';
import './styles.css';

const { Sider } = Layout;

export function SideBar() {
  const { isMobile, onCollapse, collapsed } = useLayoutMenuContext();

  return (
    <Sider
      className="side-menu"
      width={272}
      collapsedWidth={isMobile ? 0 : 70}
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <SideBarHeader />
      <SideBarUserProfile collapsed={collapsed} />
      <SideBarMenu />
    </Sider>
  );
}
