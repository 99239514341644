import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import IconDashboard from '../../../../../icons/Dashboard';
import IconLogOut from '../../../../../icons/LogOut';
import { logout } from '../../../../../services/AuthService';
import { useLayoutMenuContext } from '../../../context/LayoutMenuContext';
import * as S from './styles';

const { confirm } = Modal;

export function SideBarMenu() {
  const { setCollapsed } = useLayoutMenuContext();
  const { pathname } = useLocation();
  const history = useHistory();

  const initialMenu = pathname.replace('/', '') || 'monitoramento';

  const handleClick = useCallback((...args: any[]) => {
    const url: string = args[0]?.item?.props?.['data-url'] || '/';

    if (url === '/') return;
    history.push(`${url}`);
    setCollapsed(true);
  }, []);

  const showLogoutConfirm = useCallback(() => {
    confirm({
      title: 'Deseja sair do Trackfy?',
      okText: 'Sair',
      okType: 'primary',
      cancelText: 'Não',
      style: {
        font: 'normal 500 2rem/1.5 Work Sans, sans-serif',
        display: 'flex',
        justifyContent: 'center',
      },
      className: 'modal-logout',
      onOk: () => {
        logout();
        setTimeout(() => history.push('/'), 1000);
      },
    });
  }, []);

  return (
    <S.Menu defaultSelectedKeys={[initialMenu]} mode="inline" onClick={handleClick}>
      <S.MenuItem key="monitoramento" icon={<IconDashboard />} to="/monitoramento">
        Monitoramento
      </S.MenuItem>

      <S.MenuItem key="sair" icon={<IconLogOut />} to="/" onClick={showLogoutConfirm}>
        Sair
      </S.MenuItem>
    </S.Menu>
  );
}
