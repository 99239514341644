import { Avatar } from 'antd';
import styled from 'styled-components';

export const User = styled.div`
  padding: 0 2.1rem;
  display: flex;
  align-items: center;

  &.collapsed {
    height: max-content;
    padding: 0 10px;
    justify-content: center;

    & div {
      display: none;
    }
  }
`;

export const UserAvatar = styled(Avatar)`
  width: 3.9rem;
  height: 3.9rem;
  border: 1.625px solid #fafbff;
  border-radius: 50%;
  flex: 0 0 3.9rem;
  user-select: none;
`;

export const UserDescription = styled.div`
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #fff;
  margin-left: 1.5rem;
  user-select: none;
`;
