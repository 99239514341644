import styled from 'styled-components';

export const Table = styled.table`
  text-align: center;
  width: 100%;
  color: #4f5882;
`;

export const Thead = styled.thead`
  height: 30px;
  background: #4f5882;
  color: #ffffff;
  font-size: 16px;
  font-wieght: 500;
  margin-bottom: 5px;
`;

export const Tbody = styled.tbody`
  font-size: 14px;
  font-wieght: 400;
`;

export const OddTr = styled.tr`
  background: #f4f4f4;
  height: 40px;
`;

export const Tr = styled.tr`
  height: 40px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

export const OnlineIndicator = styled.div`
  border-radius: 30;
  height: 10;
  width: 10;
  background: ${(props: any) => (props.color === 'online' ? '#00FF00' : '#FF0000')};
  margin-left: 50%;
  margin-right: 50%;
`;
