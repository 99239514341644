import React from 'react';

import { GatewayTable } from './components/GatewayTable';
import { ScannerTable } from './components/ScannerTable';
import { TagTable } from './components/TagTable';
import * as S from './styles';

export function Monitoring() {
  return (
    <S.Container>
      <S.ContentContainer>
        <ScannerTable />
        <TagTable />
        <GatewayTable />
      </S.ContentContainer>
    </S.Container>
  );
}
