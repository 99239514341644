import styled from 'styled-components';
import { Layout } from 'antd';

export const Header = styled(Layout.Header)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background: #2f4cdd;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  box-shadow: 0px 0px 2px 1px #33333390;

  @media (min-width: 767px) {
    display: none;
  }

  & svg:first-child {
    cursor: pointer;
  }

  & svg:last-child {
    width: 100%;
    max-width: 120px;
  }
`;
