import React from 'react';

export default function ChevronDown() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 13L12 20L5 13M19 5L12 12L5 5"
        stroke="#374151"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
