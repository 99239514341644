import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import * as S from './styles';

type Props = {
  color: string;
};

export function RoundStateIndicator({ color }: Props) {
  return (
    <S.OnlineIndicatorContainer key={uuidv4()}>
      <S.OnlineIndicator color={color} />
    </S.OnlineIndicatorContainer>
  );
}
