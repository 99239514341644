import React from 'react';

import { TableHeader } from '../../components/Table/types';
import Sucess from '../../icons/Sucess';

export const gatewaysHeaders: TableHeader[] = [
  {
    title: 'Identificação',
    icon: <Sucess />,
  },
  {
    title: 'Empresa',
    icon: <Sucess />,
  },
  {
    title: 'Local',
    icon: <Sucess />,
  },
  {
    title: 'Ultima leitura',
    icon: <Sucess />,
  },
  {
    title: 'Online?',
    icon: <Sucess />,
  },
];

export const scannersHeaders: TableHeader[] = [
  {
    title: 'Identificação',
    icon: <Sucess />,
  },
  {
    title: 'Empresa',
    icon: <Sucess />,
  },
  {
    title: 'Local',
    icon: <Sucess />,
  },
  {
    title: 'Detalhe',
    icon: <Sucess />,
  },
  {
    title: 'Ultima leitura',
    icon: <Sucess />,
  },
  {
    title: 'Online?',
    icon: <Sucess />,
  },
];

export const tagsHeaders: TableHeader[] = [
  {
    title: 'Identificação',
    icon: <Sucess />,
  },
  {
    title: 'Empresa',
    icon: <Sucess />,
  },
  {
    title: 'Colaborador',
    icon: <Sucess />,
  },
  {
    title: 'Função',
    icon: <Sucess />,
  },
  {
    title: 'Ultimo acesso',
    icon: <Sucess />,
  },
  {
    title: 'Online?',
    icon: <Sucess />,
  },
];
