import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Monitoring } from '../../../../pages/Monitoring';

const { Content: AntdContent } = Layout;

export function Content() {
  return (
    <AntdContent>
      <Switch>
        <Route exact path="/monitoramento" component={Monitoring} />
      </Switch>
    </AntdContent>
  );
}
