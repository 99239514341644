import React, { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import useCollapse from 'react-collapsed';

import Table from '../../../components/Table';
import { useMonitoring } from '../useMonitoring';
import { getObjectToStringMatrix } from '../../../utils/Convert';
import { ITagsMonitoring } from '../../../types/types';
import { tagsHeaders } from '../HeadersData';
import ChevronDown from '../../../icons/ChevronDown';
import ChevronRight from '../../../icons/ChevronRight';
import * as S from '../styles';

export function TagTable() {
  const [tags, setTags] = useState<ITagsMonitoring[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ defaultExpanded: true });
  const { getTagsData, renderData } = useMonitoring();

  useEffect(() => {
    let mounted = true;
    let interval: NodeJS.Timer;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      getTagsData(currentRequest.token, setTags, setIsEmpty, setIsLoading);
      interval = setInterval(() => {
        getTagsData(currentRequest.token, setTags, setIsEmpty, setIsLoading);
      }, 1500000);
    }

    return () => {
      mounted = false;
      clearInterval(interval);
      currentRequest.cancel('Tags table request canceled by the user.');
    };
  }, []);

  return (
    <S.TableContainer>
      <S.TableHeaderContainer
        {...getToggleProps({ onClick: () => setIsExpanded((opened) => !opened) })}
      >
        <S.IconContainer>{isExpanded ? <ChevronDown /> : <ChevronRight />}</S.IconContainer>
        <S.Title>Tags</S.Title>
      </S.TableHeaderContainer>
      <div {...getCollapseProps()}>
        <Table
          tableHeaders={tagsHeaders}
          tableData={getObjectToStringMatrix(tags)}
          loading={isLoading}
          isEmpty={isEmpty}
          renderData={renderData}
        />
      </div>
    </S.TableContainer>
  );
}
