import styled from 'styled-components';

export const Container = styled.div`
  background: #0823ad;
  width: 100%;
  min-height: 100vh;
  padding: 2.6rem 1.6rem 1.6rem;

  @media (max-height: 606px) {
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.5rem 2.4rem;
  gap: 3.5rem;

  background: #f3f3f3;
  border-top-style: none;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 576px) {
    border-top-right-radius: 0;
    padding: 2.5rem 1.5rem;
  }
`;

export const IconContainer = styled.div`
  margin-top: 16px;
  margin-left: 5px;
`;

export const TableContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & .input[type='date' i]::-webkit-calendar-picker-indicator {
    background-image: none;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
`;

export const Title = styled.h3`
  font: normal 600 1.9rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
  margin-top: 20px;
  margin-left: 5px;
  height: 40px;
`;
