import React from 'react';
import { CancelToken } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { RoundStateIndicator } from '../../components/RoundStateIndicator';
import { getScanners, getTags } from '../../services/TrackfyService';
import {
  IGatewaysMonitoring,
  IModulusLogin,
  IScannersMonitoring,
  ITagsMonitoring,
} from '../../types/types';

export const useMonitoring = () => {
  function renderData(value: string) {
    switch (value) {
      case 'true':
        return <RoundStateIndicator key={uuidv4()} color="#00FF00" />;
      case 'false':
        return <RoundStateIndicator key={uuidv4()} color="#FF0000" />;
      default:
        return <td key={uuidv4()}>{value}</td>;
    }
  }

  async function getGatewaysData(
    cancelToken: CancelToken,
    login: string,
    password: string,
    getModulusAccessToken: (
      cancelToken: CancelToken,
      login: string,
      password: string
    ) => Promise<IModulusLogin>,
    getBridges: (cancelToken: CancelToken, accessToken: string) => Promise<IGatewaysMonitoring[]>,
    setGateways: (value: IGatewaysMonitoring[]) => void,
    setIsEmpty: (value: boolean) => void,
    setIsLoading: (value: boolean) => void
  ): Promise<void> {
    setIsLoading(true);

    const loginData: IModulusLogin = await getModulusAccessToken(cancelToken, login, password);
    const gateways: IGatewaysMonitoring[] = await getBridges(cancelToken, loginData.accessToken);

    if (gateways.length > 0) setIsEmpty(false);
    else setIsEmpty(true);

    setGateways(gateways);
    setIsLoading(false);
  }

  async function getScannersData(
    cancelToken: CancelToken,
    setScanners: (value: IScannersMonitoring[]) => void,
    setIsEmpty: (value: boolean) => void,
    setIsLoading: (value: boolean) => void
  ): Promise<void> {
    setIsLoading(true);
    const scanners: IScannersMonitoring[] = await getScanners(cancelToken);

    if (scanners.length > 0) setIsEmpty(false);
    else setIsEmpty(true);

    setScanners(scanners);
    setIsLoading(false);
  }

  async function getTagsData(
    cancelToken: CancelToken,
    setTags: (value: ITagsMonitoring[]) => void,
    setIsEmpty: (value: boolean) => void,
    setIsLoading: (value: boolean) => void
  ): Promise<void> {
    setIsLoading(true);
    const tags: ITagsMonitoring[] = await getTags(cancelToken);

    if (tags.length > 0) setIsEmpty(false);
    else setIsEmpty(true);

    setTags(tags);
    setIsLoading(false);
  }

  return {
    getGatewaysData,
    getScannersData,
    getTagsData,
    renderData,
  };
};
