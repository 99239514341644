import styled from 'styled-components';

export const OnlineIndicatorContainer = styled.td`
  flexdirection: 'row';
`;

export const OnlineIndicator = styled.div`
  border-radius: 30px;
  height: 10px;
  width: 10px;
  background-color: ${(props) =>
    props.color === '' || props.color === undefined ? '#FF0000' : props.color};
  margin-left: 50%;
  margin-right: 50%;
`;
