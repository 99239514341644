import React, { useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import useCollapse from 'react-collapsed';

import { useModulusApi } from '../../../hooks';
import Table from '../../../components/Table';
import { useMonitoring } from '../useMonitoring';
import { IGatewaysMonitoring } from '../../../types/types';
import { getObjectToStringMatrix } from '../../../utils/Convert';
import ChevronDown from '../../../icons/ChevronDown';
import ChevronRight from '../../../icons/ChevronRight';
import { gatewaysHeaders } from '../HeadersData';
import * as S from '../styles';

export function GatewayTable() {
  const [gateways, setGateways] = useState<IGatewaysMonitoring[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const { getGatewaysData, renderData } = useMonitoring();
  const { getBridges, getModulusAccessToken } = useModulusApi();

  useEffect(() => {
    let mounted = true;
    let interval: NodeJS.Timer;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      getGatewaysData(
        currentRequest.token,
        'admin@trackfy.com.br',
        '123456',
        getModulusAccessToken,
        getBridges,
        setGateways,
        setIsEmpty,
        setIsLoading
      );
      interval = setInterval(() => {
        getGatewaysData(
          currentRequest.token,
          'admin@trackfy.com.br',
          '123456',
          getModulusAccessToken,
          getBridges,
          setGateways,
          setIsEmpty,
          setIsLoading
        );
      }, 1800000);
    }

    return () => {
      mounted = false;
      clearInterval(interval);
      currentRequest.cancel('Gateways table request canceled by the user.');
    };
  }, []);

  return (
    <S.TableContainer>
      <S.TableHeaderContainer
        {...getToggleProps({ onClick: () => setIsExpanded((opened) => !opened) })}
      >
        <S.IconContainer>{isExpanded ? <ChevronDown /> : <ChevronRight />}</S.IconContainer>
        <S.Title>Gateways</S.Title>
      </S.TableHeaderContainer>
      <div {...getCollapseProps()}>
        <Table
          tableHeaders={gatewaysHeaders}
          tableData={getObjectToStringMatrix(gateways)}
          loading={isLoading}
          isEmpty={isEmpty}
          renderData={renderData}
        />
      </div>
    </S.TableContainer>
  );
}
