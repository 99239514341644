import React from 'react';

interface PropsTypes {
  className?: string;
  onClick: any;
}

export default function IconMenuHamburger({ className, onClick }: PropsTypes) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 2V0H18V2H0Z" fill="white" />
      <path d="M0 7H18V5H0V7Z" fill="white" />
      <path d="M0 12H18V10H0V12Z" fill="white" />
    </svg>
  );
}
