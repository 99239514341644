import axios from 'axios';

import { getConfig } from '../config/app.config';
import { ConfigKey } from '../enum/ConfigKey';
import { getRefreshToken, getToken, refreshAccessToken } from './AuthService';

const api = axios.create({
  baseURL: getConfig(ConfigKey.REACT_APP_SERVER_HOST),
});

api.interceptors.request.use(async (config) => {
  const accessToken: any = getToken();

  if (accessToken) {
    if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      const accessToken = await refreshAccessToken(refreshToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default api;
