import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Incident from '../../icons/Incident';
import { IconLoading } from '../IconLoading';
import { TableBodyProps } from './types';
import * as S from './styles';

export default function TableBody(props: TableBodyProps) {
  const { tableData, loading, isEmpty, renderData } = props;

  if (loading)
    return (
      <S.Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <IconLoading />
          </td>
        </tr>
      </S.Tbody>
    );

  if (isEmpty)
    return (
      <S.Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <S.EmptyContainer>
              <Incident />
              <span>Não há dados atualmente</span>
            </S.EmptyContainer>
          </td>
        </tr>
      </S.Tbody>
    );

  return (
    <S.Tbody>
      {tableData.map((item: string[], index: number) =>
        index % 2 === 0 ? (
          <S.Tr key={uuidv4()}>{item.map((val) => renderData(val))}</S.Tr>
        ) : (
          <S.OddTr key={uuidv4()}>{item.map((val) => renderData(val))}</S.OddTr>
        )
      )}
    </S.Tbody>
  );
}
