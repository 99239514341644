import React from 'react';

export default function ChevronRight() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 5L20 12L13 19M5 5L12 12L5 19"
        stroke="#374151"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
