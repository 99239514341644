import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Error from '../../icons/Error';
import Sucess from '../../icons/Sucess';
import api from '../../services/ApiService';
import { login } from '../../services/AuthService';
import { IAuthResponse, TypeMessages } from './types';

export const useLogin = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let waitLogin: ReturnType<typeof setTimeout>;
    if (message === 'sucess') {
      waitLogin = setTimeout(() => history.push('/monitoramento'), 1200);
    }
    return () => clearTimeout(waitLogin);
  }, [history, message]);

  const handleSignIn = async (e: any) => {
    e.preventDefault();

    if (!user || !password) {
      setMessage('warning');
      return;
    }

    setLoading(true);
    setMessage('');

    const body = { user, password };

    api
      .post('auth/restrictedlogin', body)
      .then((response: AxiosResponse) => {
        const { access_token, refresh_token, profileData } = response.data as IAuthResponse;
        login(
          access_token,
          refresh_token,
          profileData.userId.toString(),
          profileData.clientId.toString(),
          profileData.name.toString()
        );

        setMessage('sucess');
      })
      .catch(() => {
        setMessage('error');
      })
      .finally(() => setLoading(false));
  };

  const typeMessages: TypeMessages = {
    sucess: {
      message: 'Login realizado com sucesso!',
      icon: <Sucess />,
    },
    error: {
      message: 'Ops! Senha ou usuário errados. Tente novamente!',
      icon: <Error />,
    },
    warning: {
      message: 'Informe o usuário e a senha para continuar!',
      icon: <Error />,
    },
  };

  return {
    user,
    password,
    loading,
    message,
    typeMessages,
    handleSignIn,
    setUser,
    setPassword,
  };
};
