import { AxiosResponse, CancelToken } from 'axios';
import moment from 'moment';

import { requestModulusAccessToken, requestBridges } from '../services/ModulusOneService';
import { IGatewaysMonitoring, IModulusLogin } from '../types/types';
import { getDateBrFormat } from '../utils/DateTime';

export const useModulusApi = () => {
  async function getModulusAccessToken(
    cancelToken: CancelToken,
    loginParam: string,
    passwordParam: string
  ): Promise<IModulusLogin> {
    try {
      const result: AxiosResponse<any> = await requestModulusAccessToken(
        cancelToken,
        loginParam,
        passwordParam
      );
      return {
        accessToken: result.data.access_token,
        refreshToken: result.data.refresh_token,
        clientId: result.data.client_id,
        expiresIn: result.data.expires_in,
        tokenType: result.data.token_type,
        scope: result.data.scope,
      } as IModulusLogin;
    } catch (error) {
      return {} as IModulusLogin;
    }
  }

  function getLastOnlinedate(date: string): string {
    const dt: Date = new Date(date);
    const formattedDate: string = getDateBrFormat(moment(dt));
    return formattedDate;
  }

  function getBridgeStatus(date: string): string {
    const dt: Date = new Date(date);
    const currentDt: Date = new Date();

    currentDt.setHours(currentDt.getHours() - 6);

    if (dt.getTime() >= currentDt.getTime()) return 'true';

    return 'false';
  }

  function getBridgeLocation(bridge: string): string {
    let location: string = '';
    switch (bridge) {
      case 'bayer': {
        location = 'Sala das Contratadas';
        break;
      }
      case 'M10300': {
        location = 'Dsida';
        break;
      }
      case 'M10301': {
        location = 'Testes internos';
        break;
      }
      case 'M10302': {
        location = 'Pia Úmido';
        break;
      }
      case 'M10303': {
        location = 'Sala de Charlles';
        break;
      }
      case 'M10304': {
        location = 'Pia Seco';
        break;
      }
      case 'M10305': {
        location = 'Pcl3';
        break;
      }
      case 'M10306': {
        location = 'Armazem';
        break;
      }
      default: {
        location = 'Teste';
        break;
      }
    }

    return location;
  }

  async function getBridges(
    cancelToken: CancelToken,
    accessToken: string
  ): Promise<IGatewaysMonitoring[]> {
    try {
      const result: AxiosResponse<any> = await requestBridges(cancelToken, accessToken);

      return result.data._items.map(
        (bridge: any) =>
          ({
            id: bridge.name,
            owner: 'Bayer',
            place: getBridgeLocation(bridge.name),
            lastReading: getLastOnlinedate(bridge.dateReply),
            state: getBridgeStatus(bridge.dateReply),
          } as IGatewaysMonitoring)
      );
    } catch (error) {
      return {} as IGatewaysMonitoring[];
    }
  }

  return {
    getModulusAccessToken,
    getBridges,
  };
};
