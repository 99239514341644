import React from 'react';

import TableBody from './TableBody';
import { TableProps } from './types';
import * as S from './styles';

export default function Table(props: TableProps) {
  const { tableHeaders, tableData, loading, isEmpty, renderData } = props;

  return (
    <S.Table>
      <S.Thead>
        <tr>
          {tableHeaders.map((item: any, index: number) => (
            <td key={`${index}-${item.title}`}>{item.title}</td>
          ))}
        </tr>
      </S.Thead>
      <TableBody
        tableData={tableData}
        renderData={renderData}
        loading={loading}
        isEmpty={isEmpty}
      />
    </S.Table>
  );
}
