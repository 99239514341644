import { Layout as AntdLayout } from 'antd';
import 'antd/dist/antd.css';
import './styles.css';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import { useHistory, useLocation } from 'react-router-dom';

import { useMatchMedia } from '../../hooks';
import { isAuthenticated } from '../../services/AuthService';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { SideBar } from './components/SideBar';
import { Login } from '../../pages/Login';
import { LayoutMenuContextProvider } from './context/LayoutMenuContext';

export function Layout() {
  const isMobile: boolean = useMatchMedia(767);
  const { pathname } = useLocation();
  const history = useHistory();

  if (pathname === '/') {
    return <Login />;
  }

  if (!isAuthenticated()) {
    history.push('/');
    return null;
  }

  return (
    <AntdLayout
      style={{
        minHeight: '100vh',
        overflow: 'hidden',
        paddingTop: `${isMobile ? '50px' : '0'}` /* makes the sidebar roll above the content */,
        paddingLeft: `${isMobile ? '0' : '70px'}` /* makes the sidebar roll above the content */,
      }}
    >
      <LayoutMenuContextProvider>
        <Header />
        <SideBar />
      </LayoutMenuContextProvider>

      <AntdLayout style={{ zIndex: 10 }}>
        <ScrollBar>
          <Content />
        </ScrollBar>
      </AntdLayout>
    </AntdLayout>
  );
}
