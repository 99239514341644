import axios, { AxiosResponse, CancelToken } from 'axios';

export async function requestModulusAccessToken(
  cancelToken: CancelToken,
  loginParam: string,
  passwordParam: string
): Promise<AxiosResponse> {
  const api = axios.create({ baseURL: 'https://api.modulusone.com/' });

  const result: AxiosResponse<any> = await api.post(
    `login`,
    {
      login: loginParam,
      password: passwordParam,
    },
    {
      cancelToken,
    }
  );

  return result;
}

export async function requestBridges(
  cancelToken: CancelToken,
  accessToken: string
): Promise<AxiosResponse> {
  const authorizationToken: string = `Bearer ${accessToken}`;
  const api = axios.create({ baseURL: 'https://api.modulusone.com/' });

  const result: AxiosResponse<any> = await api.get(`bridge`, {
    headers: {
      authorization: authorizationToken,
    },
    params: {},
    cancelToken,
  });

  return result;
}
