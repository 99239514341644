import { AxiosResponse, CancelToken } from 'axios';

import api from './ApiService';
import { IScannersMonitoring, ITagsMonitoring } from '../types/types';

export async function getScanners(cancelToken: CancelToken): Promise<IScannersMonitoring[]> {
  try {
    const result: AxiosResponse<any> = await api.get(`v1/resourceMonitoring/scanners`, {
      params: {},
      cancelToken,
    });
    return result.data as IScannersMonitoring[];
  } catch (error) {
    return [] as IScannersMonitoring[];
  }
}

export async function getTags(cancelToken: CancelToken): Promise<ITagsMonitoring[]> {
  try {
    const result: AxiosResponse<any> = await api.get(`v1/resourceMonitoring/tags`, {
      params: {},
      cancelToken,
    });
    return result.data as ITagsMonitoring[];
  } catch (error) {
    return [] as ITagsMonitoring[];
  }
}
